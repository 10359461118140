import { Position, Table, Tooltip } from "evergreen-ui";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { getTicker24h } from "../../utils/binance/api";
import { getCoinLogoBySymbol, prettifyNumber } from "../../utils/tools";

type MarketsTableRowProps = {
  _1stCoin: string;
  _2ndCoin: string;
  coinName: string;
  price: number;
  priceChange: number;
  volume: number;
  low: number;
  high: number;
};

const MarketsTableRow = (props: MarketsTableRowProps) => {
  const f = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 6 });
  const thClass = "align-middle text-sm px-4 sm:px-6 py-5 font-medium";

  return (
    <tr className="border-b">
      <th className="align-middle text-sm px-4 sm:px-6 py-5 font-medium flex items-center">
        <img src={getCoinLogoBySymbol(props._1stCoin)} className="w-8 h-8 rounded-full mr-4"></img>
        <Tooltip content={props.coinName} position={Position.TOP_LEFT} appearance="card">
          <div className="">
            <span className="font-semibold">{props._1stCoin}</span>
          </div>
        </Tooltip>
      </th>
      <th className={thClass}>{"$" + f.format(props.price)}</th>
      <th className={thClass + (props.priceChange > 0 ? " text-green-600" : " text-red-500")}>
        {(props.priceChange > 0 ? "+" : "") + props.priceChange + "%"}
      </th>
      <th className={thClass}>{"$" + f.format(props.low)}</th>
      <th className={thClass}>{"$" + f.format(props.high)}</th>
      <th className={thClass}>{"$" + prettifyNumber(props.volume)}</th>
      <th className={thClass}>
        <Link className="text-blue-700 hover:font-bold" to={`${props._1stCoin}_USDT`}>
          Trade
        </Link>
      </th>
    </tr>
  );
};

export default MarketsTableRow;

import React from "react";
import { getCoinLogoBySymbol, getCoinNameFromSymbol } from "../../utils/tools";

type HeroTickerProps = {
  title: string;
  pairs: Ticker[];
};

const HeroTicker = (props: HeroTickerProps) => {
  const f = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 6 });

  return (
    <div className="flex flex-row flex-wrap px-6 py-4 bg-white rounded drop-shadow-md sm:drop-shadow-lg sm:hover:drop-shadow-lg sm:hover:scale-105 transition duration-500 ease-in-out">
      <h2 className="w-full text-xs font-medium text-gray-400 mb-4">{props.title}</h2>
      <div className="w-full text-sm">
        {props.pairs.map((ticker, index) => (
          <div key={index} className="grid grid-cols-3 gap-x-2 mb-4">
            <div className="flex items-center">
              <img
                className="w-6 h-6 rounded-full mr-3"
                src={getCoinLogoBySymbol(getCoinNameFromSymbol(ticker.symbol))}
              ></img>
              <div className="font-semibold text-gray-800">
                {getCoinNameFromSymbol(ticker.symbol)}
              </div>
            </div>
            <div className="flex items-center">{"$" + f.format(ticker.lastPrice)}</div>
            <div className={"flex items-center justify-end " + (ticker.priceChangePercent > 0 ? "text-green-600" : "text-red-500")}>
              {(ticker.priceChangePercent > 0 ? "+" : "") + `${ticker.priceChangePercent}%`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroTicker;
